<template>
  <div class="self-center">
    <div class="d-flex flex-row flex-wrap">
      <b-button
        v-b-tooltip.hover.left="'Click here to view the User details'"
        class="btn-icon grid-btn mr-1 my-auto"
        variant="outline-dark"
        @click="viewRecord"
      >
        <feather-icon
          icon="EyeIcon"
          svg-classes="hover:text-primary stroke-current"
        />
      </b-button>

      <!-- <b-button
        v-b-tooltip.hover.top="'Download Report'"
        class="btn-icon grid-btn"
        variant="outline-dark"
      >
        <feather-icon
          icon="DownloadIcon"
          svg-classes="hover:text-primary stroke-current"
        />
      </b-button> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'CellRendererActions',
  props: {
    params: Object,
  },
  data() {
    return {
      isLoading: false,
    }
  },
  methods: {
    viewRecord() {
      const report_name = `${this.params.data.report_name}-${this.params.data.id}`
      this.$router.push({
        name: 'platform-analytics-view',
        params: { report_name },
      })
    },
  },
}
</script>
