<template>
  <b-card class="h-100 w-100">
    <b-overlay :show="isLoading">
      <b-card-title>Reports</b-card-title>

      <div style="min-width: 50vw; min-height: 20vw; width: 100%; height: 100%">
        <vue-good-table
          mode="remote"
          :pagination-options="{
            enabled: true,
            perPageDropdown: [10, 20, 50, 100],
            dropdownAllowAll: false,
          }"
          :select-options="{
            enabled: true,
            selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: false, // disable the select info panel on top
            selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
          }"
          :is-loading.sync="isLoading"
          :total-rows="totalRecords"
          :rows="reportData"
          :columns="columnDefs"

          style-class="vgt-table condensed bordered"
          compact-mode
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter"
          @on-per-page-change="onPerPageChange"
          @on-selected-rows-change="onSelectionChange"
        >
          <template
            slot="column-filter"
            slot-scope="props"
          >
            <span
              v-if="props.column.field === 'updated_on'"
              class="mt-2"
            >
              <flat-pickr
                v-model="updated_on_filter"
                :config="configPicker"
                class="vgt-input"
                placeholder="Filter Date Modified"
                @input="(value) => updateFilters(props.column.field, value)"
              />
            </span>

            <span
              v-else-if="props.column.field === 'created_on'"
              class="mt-2"
            >
              <flat-pickr
                v-model="created_on_filter"
                :config="configPicker"
                class="vgt-input"
                placeholder="Filter Added On"
                @input="(value) => updateFilters(props.column.field, value)"
              />
            </span>
          </template>

          <template
            slot="table-row"
            slot-scope="props"
          >
            <!-- Column: Status -->
            <span v-if="props.column.field === 'report_name'">
              <CellRendererLink
                :params="{
                  user_id: $store.state.auth.ActiveUser.id,
                  id: props.row.id,
                  value: props.row.report_name,
                  data: props.row,
                }"
              />
            </span>

            <span v-else-if="props.column.field === 'actions'">

              <CellRendererActions
                :params="{
                  user_id: $store.state.auth.ActiveUser.id,
                  id: props.row.id,
                  data: props.row,
                }"
              />
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

        </vue-good-table>
      </div>
    </b-overlay>
  </b-card>
</template>

<script>
import { format } from 'date-fns'
import { onResizeMixins } from '@/components/mixins/onResizeMixins'
import { VueGoodTable } from 'vue-good-table'
import flatPickr from 'vue-flatpickr-component'

import CellRendererLink from './cell-renderer/CellRendererLink.vue'
import CellRendererActions from './cell-renderer/CellRendererActions.vue'

export default {
  components: {
    VueGoodTable,
    flatPickr,

    CellRendererLink,
    CellRendererActions,
  },
  mixins: [onResizeMixins],
  data() {
    return {
      reportData: [],

      // AgGrid gridApi: null,
      // blur_check: 'blur',
      isLoading: false,
      context: null,
      agColumnDefs: [
        {
          headerName: 'Report Name',
          field: 'event_group_name',
          filter: true,
          width: 200,
          cellRendererFramework: 'CellRendererLink',
        },
        {
          headerName: 'Created By',
          field: 'created_by',
          filter: true,
          width: 100,
        },
        {
          headerName: 'Date Created',
          field: 'created_on',
          filter: true,
          width: 150,
        },
        {
          headerName: 'Date Modified',
          field: 'updated_on',
          filter: true,
          width: 150,
        },
        {
          headerName: 'Actions',
          field: 'edit_delete',
          width: 80,
          cellRendererFramework: 'CellRendererActions',
        },
      ],

      // Cell Renderer Components
      components: {
        CellRendererLink,
        CellRendererActions,
      },

      // vue-good-table
      totalRecords: 0,
      pagination: {
        skip: 0,
        limit: 10,
      },
      serverParams: {
        columnFilters: {
        },
        sort: {

        },
        page: 1,
        perPage: 10,
      },
      selectedRows: null,

      columnDefs: [
        {
          label: 'Report Name',
          field: 'report_name',
          filterOptions: {
            trigger: 'enter',
            enabled: true,
            placeholder: '',
          },
          // pinned: true,
          cellRendererFramework: 'CellRendererLink',
        },
        {
          label: 'Created By',
          field: 'created_by_name',
          filterOptions: {
            trigger: 'enter',
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: 'Date Modified',
          field: 'updated_on',
          type: 'date',
          dateInputFormat: 'do MMM yyyy HH:mm', // expects 2018-03-16
          dateOutputFormat: 'do MMM yyyy HH:mm', // outputs Mar 16th 2018
          tdClass: 'text-left',
          thClass: 'text-left',
          filterOptions: {
            customFilter: true,
            trigger: 'enter',
            enabled: true,
            placeholder: '',

            filterDropdownItems: [
              { value: 'active', text: 'Active' },
              { value: 'deactivated', text: 'Deactivated' },
            ],
          },
        },
        {
          label: 'Actions',
          field: 'actions',
          cellRendererFramework: 'CellRendererActions',
        },
      ],


      configPicker: {
        altInput: true,
        enableTime: false,
      },
      updated_on_filter: null,
      created_on_filter: null,
    }
  },
  mounted() {
    this.reportData = []
    this.getData()
  },
  methods: {
    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps }
      this.getData()
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage })
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage })
    },

    onSortChange(params) {
      if (params[0]) {
        const fieldName = params[0].field
        const sortDetails = { }
        sortDetails[fieldName] = params[0].type
        this.updateParams({ sort: sortDetails })
      }
    },

    onColumnFilter(params) {
      this.updateParams(params)
    },
    onSelectionChange(params) {
      this.selectedRows = params.selectedRows
    },
    updateFilters(column, value) {
      const currentFilters = this.serverParams.columnFilters
      currentFilters[column] = value
      this.updateParams({ columnFilters: currentFilters })
    },

    getData() {
      const { token } = this.$store.state.auth.ActiveUser
      this.$http.defaults.headers.common.Authorization = `Token ${token}`
      this.isLoading = true

      let query = ''
      query = `?params=${JSON.stringify(this.serverParams)}`

      this.$http
        .get(`/api/event-reports${query}`)
        .then(response => {
          const output = response.data

          this.reportData = []
          this.reportData = output.reports
          this.reportData.unshift(
            {
              report_name: 'General Analytics',
              created_by_name: 'system',
              created_on: format(new Date(), 'do MMM yyyy HH:mm'),
              updated_on: format(new Date(), 'do MMM yyyy HH:mm'),
            },
          )
          // this.gridApi.setPinnedTopRowData(
          //   [this.reportData[0]],
          // )
          // this.reportData.shift()

          // this.reportData.push(
          //   {
          //     event_group_name: 'Event Analytics',
          //     created_by: 'system',
          //     created_at: new Date(),
          //     updated_at: new Date(),
          //   },
          // )

          // for (let index = 0; index < 12; index++) {
          //   this.reportData.push(
          //     {
          //       event_group_name: 'Event Analytics ' + index,
          //       created_by: 'system',
          //       created_at: new Date(),
          //       updated_at: new Date(),
          //     },
          //   )
          // }
          this.isLoading = false
        })
        .catch(error => {
          this.$toast(
            {
              component: this.$toastContent,
              props: {
                title: 'Operation Failed',
                icon: 'CheckCircleIcon',
                text: 'Could not retrieve the event reports. Please try again later.',
                variant: 'danger',
              },
            },
            { timeout: this.$longestTimeout },
          )

          this.isLoading = false
        })
    },
    capitalizeString(string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    formatDate(oldDate) {
      return format(new Date(oldDate), 'yyyy-MM-dd')
    },
  },
}
</script>

<style>
</style>
